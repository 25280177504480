import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

function isPointBetweenPoints(currPoint, point1, point2) {
  const cpX = Math.abs(currPoint.letterGridX);
  const cpY = Math.abs(currPoint.letterGridY)
  const lowestX = Math.abs(Math.min(point1.letterGridX, point2.letterGridX))
  const highestX = Math.abs(Math.max(point1.letterGridX, point2.letterGridX))
  const lowestY = Math.abs(Math.min(point1.letterGridY, point2.letterGridY))
  const highestY = Math.abs(Math.max(point1.letterGridY, point2.letterGridY))

  const isBetween = ()=> {
    return((cpY) >= (lowestY) &&
    (cpY) <= (highestY) &&
    (cpX) >= (lowestX) &&
    (cpX <= (highestX)))
  }

  if(!isBetween()){
    return false
  }
  
  let dxc = currPoint.letterGridX - point1.letterGridX;
  let dyc = currPoint.letterGridY - point1.letterGridY;
  
  let dxh = currPoint.letterGridX - point2.letterGridX;
  let dyh = currPoint.letterGridY - point2.letterGridY;

  const isHalf = ()=>{
  
    const sideA = Math.sqrt(
    Math.pow(point1.letterGridX -point2.letterGridX,2) + 
    Math.pow(point1.letterGridY -point2.letterGridY,2)
  )

  const sideB = Math.sqrt(
    Math.pow(point2.letterGridX -currPoint.letterGridX, 2) + 
    Math.pow(point2.letterGridY -currPoint.letterGridY,2)
  )

  const sideC = Math.sqrt(
    Math.pow(currPoint.letterGridX -point1.letterGridX, 2) + 
    Math.pow(currPoint.letterGridY -point1.letterGridY,2)
  )

  const calcs = (Math.pow(sideA,2)+Math.pow(sideC,2)-Math.pow(sideB,2)) / (2*sideA*sideC)

  console.log(Math.acos(calcs))

   return Math.acos(calcs) <= 91/360
  }


  if(dxc/dyc == 1){
      
      return false
  }

  if(dxc/dyc == -1){
      
      return false
  }

  if(dxc/dyc == 0){
      
      return false
  }
  if(dxc/dyc == Infinity){
      
      return false
  }
  if(dyc/dxc == 0){
    return true;
  }
  return false;
  console.log(dxc,dyc, point1, point2)

  let dxl = point2.letterGridX - point1.letterGridX;
  let dyl = point2.letterGridY - point1.letterGridY;

  let  cross = dxc * dyl - dyc * dxl;
  let threshold = 0.5; 
  if (Math.abs(cross) > threshold) return false
  if (Math.abs(dxl) >= Math.abs(dyl))
    return dxl > 0
      ? point1.letterGridX <= currPoint.letterGridX && currPoint.letterGridX <= point2.letterGridX
      : point2.letterGridX <= currPoint.letterGridX && currPoint.letterGridX <= point1.letterGridX;
  else
    return dyl > 0
      ? point1.letterGridY <= currPoint.letterGridY && currPoint.letterGridY <= point2.letterGridY
      : point2.letterGridY <= currPoint.letterGridY && currPoint.letterGridY <= point1.letterGridY;
}

export interface letterGridInteraction {
  letterGridX: number;
  letterGridY: number;
  interaction: "hover" | "down" | "up" |"line";
}

export interface puzzleState {
  interactions: letterGridInteraction[];
  gridWidth: number;
  gridHeight: number;
}

const initialState: puzzleState = {
  interactions: [],
  gridWidth: 20,
  gridHeight: 20,
};

// Slice
const puzzleSlice = createSlice({
  name: "puzzle",
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    setInteraction: (state, action) => {
      state.interactions = [...state.interactions, action?.payload];
    },
    clearAllInteractions: (state) => {
      state.interactions = [];
    },
    clearInteraction: (state, action) => {
      const { letterGridX, letterGridY, interaction } = action?.payload;

      const calculateRemainingInteraction = () => {
        if (!interaction && !letterGridX && !letterGridY) {
          return [];
        } else {
        }
        return state.interactions.filter((item) => {
          const arrItem = JSON.stringify(item);
          if (interaction) {
            return !(
              interaction == item.interaction &&
              item.letterGridX == letterGridX &&
              item.letterGridY == letterGridY
            );
          }

          return !(
            item.letterGridX == letterGridX && item.letterGridY == letterGridY
          );
        });
      };
      state.interactions = calculateRemainingInteraction();
    },
  },
});

// Reducers
export default puzzleSlice.reducer;

// Selectors
export const puzzleData = (state: RootState) => state.puzzle;
export const letterInteraction =
  ({ letterGridX, letterGridY }) =>
  (state: RootState) => {
    const point1 = state.puzzle.interactions.find(item=>item.interaction === 'down');
    const point2 = state.puzzle.interactions.find(item=>item.interaction === 'hover');

    if(point1 && point2 && isPointBetweenPoints({letterGridX, letterGridY}, point1, point2)){
      return {
        letterGridX, letterGridY, interaction: 'line'
      }
    }

    if (!letterGridX || !letterGridY) {
      return null;
    }
    return state.puzzle.interactions?.find(
      (interaction) =>
        interaction?.letterGridX == letterGridX &&
        interaction?.letterGridY == letterGridY
    );
  };

export const inInteraction = (state: RootState) => 
  state.puzzle.interactions.filter(
    (interaction) => interaction.interaction === "down"
  ).length;

export const interactions = (state: RootState) => 
state.puzzle.interactions;

// Actions
export const { clearAllInteractions, clearInteraction, setInteraction, reset } =
  puzzleSlice.actions;
