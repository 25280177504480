import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from './store';
import { RootState, Thunk, Dispatch } from './store';

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark'
}

interface UIState {
  themeMode: ThemeMode;
  googleId: string;
  sheets: any[];
}

const initialState: UIState = {
  themeMode: ThemeMode.LIGHT,
  googleId: '',
  sheets: []
};

// Slice
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setGoogleId: (state, action) =>{
      state.googleId = action.payload
    },
    setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
      const { payload } = action;
      state.themeMode = payload;
    },
    setSheets: (state, action) => {
        state.sheets =action?.payload;
    }
  }
});

// Reducers
export default uiSlice.reducer;

// Selectors
export const uiSelector = (state: RootState) => state.ui;

// Actions
export const { setGoogleId, setThemeMode, setSheets } = uiSlice.actions;

// Thunks
//@ts-ignore
export const toggleThemeMode = (): Thunk => (dispatch: Dispatch) => {
  const { themeMode } = store.getState().ui;
  const mode = themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;

  dispatch(setThemeMode(mode));
};