import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from './store';
import { RootState, Thunk, Dispatch } from './store';
import {badWords} from '../utils/badwords'
import {wordFilter, forbiddenWordDictionaryFilter, compressAlphabet, simplifyAlphabet, fullAlphabet, defaultWordFilter, wordFilterFromString } from '../utils/word';

interface WordSearchConfig {
  backwardsProbability: number;
  diacritics: boolean;
  dictionary: string[];
  words: string[];
  disabledDirections: ("N"|"S"|"E"|"W"|"SE"|"NE"|"SW"|"NW")[]
  disableForbiddenWords: boolean;
  maxWords: number;
  seed: number;
  alphabet: string,
  hiddenWords: string[];
  wordFilter: string,
  upperCase: boolean;
  forbiddenWords: string[];
  maxRetries: number;
  cols: number;
  rows: number;
  alphabetOnly: boolean;
}

const defaultDictionary = ['Zebra', 'Item'];

const initialState: WordSearchConfig = {
  alphabet: fullAlphabet,
  backwardsProbability: 0.03,
  diacritics: true,
  dictionary: defaultDictionary,
  words: defaultDictionary.map(wordFilterFromString()),
  disabledDirections: ["N", "W", "NW", "SW"],
  maxWords: 100,
  seed: 1,
  hiddenWords: [],
  wordFilter: defaultWordFilter,
  upperCase: true,
  forbiddenWords :badWords,
  maxRetries: 10,
  disableForbiddenWords: false,
  cols: 20,
  rows: 20,
  alphabetOnly: false
};

// Slice
const wordSearchSlice = createSlice({
  name: 'wordSearchConfig',
  initialState,
  reducers: {
    resetWords: (state)=>{
      state = initialState
    },
    setWords: (state, action) => {
      state.words = action?.payload;  
      state.dictionary =action?.payload.map(wordFilterFromString(state.wordFilter));
      state.alphabet = state.alphabetOnly ? simplifyAlphabet(compressAlphabet(action.payload.map(wordFilterFromString(state.wordFilter)))) : state.alphabet;
    },
    setSeed: (state,action)=>{
      state.seed = action?.payload
    },
    setHiddenWords: (state,action)=>{
      state.hiddenWords = action.payload;
    },
    setWordSearchConfig: (state, action) => {
      state.seed = action.payload?.seed;
      state.backwardsProbability = action.payload?.backwardsProbability;
      state.cols = action.payload?.cols;
      state.rows = action.payload?.rows;
      state.alphabet =action.payload.alphabetOnly ? simplifyAlphabet(compressAlphabet(state.dictionary.map(wordFilterFromString(state.wordFilter)), state.wordFilter)) : action.payload.alphabet;
      state.alphabetOnly = action.payload.alphabetOnly;
      state.disableForbiddenWords = action.payload?.disableForbiddenWords;
      state.diacritics = action.payload?.diacritics;
      state.wordFilter = action.payload?.wordFilter;
      state.disabledDirections = action.payload?.disabledDirections;
    }
  }
});

// Reducers
export default wordSearchSlice.reducer;

// Selectors
export const wordsSearchSelector = (state: RootState) => state.wordSearch;
export const visibleWordsSelector = (state: RootState) => {
  const {words, hiddenWords} = state.wordSearch;
  return words.filter(word=>!hiddenWords.includes(word))
}

export const hiddenWordsSelector = (state: RootState) => {
  const {words, hiddenWords} = state.wordSearch;
  return words.filter(word=>hiddenWords.includes(word))
}


export const wordSearchConfigSelector = (state: RootState) => {
  const {
    backwardsProbability,
    cols,
    diacritics,
    dictionary,
    disabledDirections,
    disableForbiddenWords,
    forbiddenWords,
    maxRetries,
    maxWords,
    rows, 
    seed,
    upperCase,
    alphabet,
  } = state.wordSearch;

    return {  
      backwardsProbability,
      alphabet, 
      cols,
      diacritics,
      dictionary,
      disabledDirections,
      forbiddenWords: disableForbiddenWords ? [] : forbiddenWordDictionaryFilter(dictionary, forbiddenWords),
      maxRetries,
      maxWords,
      rows,
      seed,
      upperCase,
    }
    }

// Actions
export const { resetWords, setSeed, setWordSearchConfig, setHiddenWords, setWords } = wordSearchSlice.actions;
