exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-generator-index-tsx": () => import("./../../../src/pages/app/generator/index.tsx" /* webpackChunkName: "component---src-pages-app-generator-index-tsx" */),
  "component---src-pages-app-generator-sheets-[title]-tsx": () => import("./../../../src/pages/app/generator/sheets/[title].tsx" /* webpackChunkName: "component---src-pages-app-generator-sheets-[title]-tsx" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-2-tsx": () => import("./../../../src/pages/index2.tsx" /* webpackChunkName: "component---src-pages-index-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-keys-tsx": () => import("./../../../src/pages/keys.tsx" /* webpackChunkName: "component---src-pages-keys-tsx" */),
  "component---src-pages-old-2-tsx": () => import("./../../../src/pages/old2.tsx" /* webpackChunkName: "component---src-pages-old-2-tsx" */),
  "component---src-pages-old-tsx": () => import("./../../../src/pages/old.tsx" /* webpackChunkName: "component---src-pages-old-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-puzzles-tsx": () => import("./../../../src/pages/puzzles.tsx" /* webpackChunkName: "component---src-pages-puzzles-tsx" */),
  "component---src-templates-book-key-js": () => import("./../../../src/templates/BookKey.js" /* webpackChunkName: "component---src-templates-book-key-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-category-key-js": () => import("./../../../src/templates/CategoryKey.js" /* webpackChunkName: "component---src-templates-category-key-js" */),
  "component---src-templates-puzzle-js": () => import("./../../../src/templates/Puzzle.js" /* webpackChunkName: "component---src-templates-puzzle-js" */),
  "component---src-templates-redirect-answer-key-js": () => import("./../../../src/templates/RedirectAnswerKey.js" /* webpackChunkName: "component---src-templates-redirect-answer-key-js" */),
  "component---src-templates-redirect-buy-book-js": () => import("./../../../src/templates/RedirectBuyBook.js" /* webpackChunkName: "component---src-templates-redirect-buy-book-js" */)
}

