export const fullAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const defaultWordFilter = "[\\s.,\\/#!$%\\^&\\*;:{}’=\\-_`~()'\"0-9]";

export const wordFilterFromString = (filter: string = defaultWordFilter) =>
  wordFilter(RegExp(filter, "g"));

export const wordFilter = (filter: RegExp) => (word: string) =>
  word.replace(filter, "");
  
export const forbiddenWordDictionaryFilter = (
  dictionary: string[],
  forbiddenWords: string[]
) => {
  const checkForMatch = (word: string) =>
    !dictionary.some((dictionaryWord: string) =>
      dictionaryWord.toLocaleLowerCase().includes(word.toLocaleLowerCase())
    );
  return forbiddenWords.filter((word) => checkForMatch(word));
};

export const compressAlphabet = (words: string[], filter = defaultWordFilter) =>
  [
    ...new Set(
      words
        .map(wordFilterFromString(filter))
        .reduce((prev, next) => `${prev}${next}`)
        .split("")
    ),
  ].join("");

export const simplifyAlphabet = (alpha: string, filter = defaultWordFilter) =>
  compressAlphabet([alpha.toLocaleUpperCase()], filter)
    .split("")
    .sort((a, b) => (a > b ? 1 : -1))
    .join("");
