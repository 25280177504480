
export const badWords = `
a$$
a2m
a54
a55
ass
cum
fag
hiv
hoe
jiz
kkk
pee
s&m
s0b
sex
t1t
tit
xxx
2g1c
4r5e
5h1t
5hit
anal
anal
anus
ar5e
arse
barf
bdsm
bint
blow
bomd
bone
bong
boob
bung
butt
c0ck
caca
cawk
cipa
cl1t
clit
cnut
cock
coon
crap
cums
cunn
cunt
d0ng
d1ck
dago
damn
dick
dike
dink
dlck
dong
drug
dvda
dyke
fack
fagg
fags
faig
fart
fcuk
feck
foad
foah
fook
fuck
fuks
fvck
fxck
gash
gays
ghay
ghey
gook
gtfo
guro
h0m0
h0mo
he11
hebe
heeb
hell
hemp
herp
hoar
hoer
hom0
homo
hore
japs
jerk
jism
jizm
jizz
jock
kawk
kike
kill
klan
knob
kums
kunt
kwif
lech
lmao
loin
lube
lust
m0f0
m0fo
mams
maxi
meth
mick
milf
mof0
mofo
mong
muff
nads
nazi
nude
oral
orgy
ovum
p0rn
paki
pawn
pedo
phuk
phuq
pimp
piss
poof
poon
poop
porn
prig
prod
pron
pthc
pube
puss
pust
puto
quim
racy
rape
rump
scag
scat
scum
seks
sexo
sexy
sh!+
sh!t
sh1t
shag
shit
shiz
skag
slag
slut
smeg
smut
spac
spic
spik
stfu
suck
taff
taig
tard
tart
teat
teez
terd
thug
titi
tits
titt
toke
turd
tush
tw4t
twat
ugly
wang
wank
weed
whiz
womb
yaoi
zubb
a_s_s
ahole
arian
arrse
aryan
asses
b!tch
b00bs
b17ch
b1tch
babes
balls
bawdy
bi+ch
bimbo
bitch
boang
bogan
boink
boned
boner
boobs
booby
boong
booty
booze
boozy
bosom
bowel
bunga
busty
chinc
chink
choad
chode
clits
cocks
coons
crabs
crack
cunny
cunts
d1ld0
d1ldo
dagos
deggo
dicks
dildo
dinks
dirsa
dirty
doosh
dopey
drugs
drunk
duche
dummy
dykes
ecchi
erect
f4nny
faggs
fagot
faigt
fanny
fanyy
fecal
felch
fisty
flaps
freex
frigg
fubar
fucka
fucks
fuker
fux0r
ganja
gaydo
gippo
glans
gonad
gooch
gooks
grope
gspot
guido
herpy
heshe
hoare
hobag
homey
honky
hooch
horny
hussy
hymen
injun
jaggi
juggs
junky
kafir
kikes
kinky
kooch
kraut
kunja
labia
leper
lesbo
lezbo
lezza
lezzy
lmfao
loins
lusty
massa
minge
mo-fo
moron
mutha
n1gga
naked
nappy
negro
nigga
ninny
nonce
nooky
opium
organ
ovary
ovums
paddy
pansy
panty
pasty
penis
phuck
phuks
pikey
pinko
porno
potty
prick
prude
pubes
pubic
pubis
punky
punta
pusse
pussi
pussy
queaf
queef
queer
raped
raper
rapey
reich
renob
revue
rtard
ruski
s hit
s-o-b
sambo
screw
scrog
scrot
scrud
semen
shite
shits
shitt
shota
sissy
skank
skeet
slave
slope
sluts
snuff
sodom
souse
spade
sperm
spick
spiks
spook
spunk
strip
sucks
teets
teste
titty
toots
tramp
tushy
twats
twink
twunt
unwed
urine
v1gra
vixen
vodka
vomit
vulva
w00se
wanky
wazoo
wench
wh0re
whoar
whore
willy
woody
yiffy
yobbo
aeolus
areola
areole
assbag
asshat
assman
asswad
azazel
bampot
banger
beaner
beatch
beater
beaver
bender
beotch
biatch
bigger
bimbos
bitchy
bloody
bodily
bohunk
bollok
bollox
boners
booger
bookie
boonga
booobs
bootee
bootie
boozer
bosomy
bowels
breast
buceta
bugger
bummer
cahone
cervix
chincs
chinky
choade
chodes
climax
clitty
clunge
cocain
coital
commie
condom
coochy
cooter
crappy
cretin
crikey
crotte
cummer
cummin
cunnie
cunntt
cyalis
d0uch3
d0uche
dammit
damned
damnit
darkie
diddle
dildos
dimwit
dingle
doggin
dommes
doofus
dookie
douch3
douche
dumass
erotic
escort
eunuch
extacy
extasy
facial
fagbag
fagged
faggit
faggot
fagots
fatass
fcuker
fecker
feltch
femdom
fenian
fisted
flamer
flange
floozy
fondle
foobar
fooker
frigga
fucked
fucker
fuckin
fuckme
fuckup
fukker
fukkin
fukwit
g-spot
gayass
gaybob
gaysex
gaywad
gigolo
ginger
goatcx
goatse
godamn
goddam
gokkun
gonads
gringo
he-she
hentai
heroin
herpes
hitler
homoey
honkey
hookah
hooker
hootch
hooter
hotsex
humped
inbred
incest
jagoff
jerked
jizzed
junkie
knobed
kondum
kootch
kummer
l3i+ch
l3itch
lesbos
lezbos
lezzie
lolita
looney
menses
midget
minger
molest
moolie
munter
murder
muther
n1gger
nambla
napalm
nazism
nigg3r
nigg4h
niggah
niggas
niggaz
nigger
niggle
niglet
nimrod
nipple
nudity
nutter
nympho
opiate
orally
orgasm
orgies
pantie
pastie
pecker
peepee
penial
penile
peyote
phalli
phuked
pimpis
pissed
pisser
pisses
pissin
polack
pornos
pricks
psycho
punani
punany
pussys
queero
queers
quicky
r-tard
raping
rapist
raunch
rectal
rectum
rectus
reefer
retard
rimjaw
rimjob
ritard
s.o.b.
sadism
sadist
sanger
schizo
scroat
scrote
seaman
seamen
seduce
sexual
shited
shitey
shitty
sleaze
sleazy
smegma
smutty
snatch
sniper
sodomy
soused
spooge
steamy
stiffy
stoned
stroke
stupid
sucked
tampon
tawdry
testee
testes
testis
thrust
tinkle
tities
tosser
tranny
trashy
twatty
undies
urinal
uterus
v14gra
va-j-j
vagina
valium
viagra
virgin
voyeur
vulgar
wanker
wedgie
weenie
weewee
weiner
weirdo
whitey
whored
whores
wigger
xrated
yeasty
a$$hole
a55hole
analsex
apeshit
ass-hat
assault
assbang
assbite
asscock
assface
assfuck
assh0le
asshead
assho1e
asshole
asshore
asskiss
asslick
assshit
asswipe
axwound
badfuck
ballbag
banging
barface
bastard
bazooms
beaners
bellend
bestial
bigbutt
bigtits
bitched
bitcher
bitches
bitchez
bitchin
blow me
blowjob
boiolas
bollick
bollock
bondage
boobies
boooobs
breasts
breeder
buggery
bukkake
bum boy
bumclat
bumfuck
buttman
c-0-c-k
c-o-c-k
c-u-n-t
c.0.c.k
c.u.n.t
camgirl
camslut
clogwog
cocaine
cockass
cockeye
coochie
cracker
cripple
cumdump
cumming
cumshot
cumslut
cumtart
cuntass
cuntbag
cuntrag
dickbag
dickish
dickwad
dickwod
diligaf
dipship
dipshit
dogging
dolcett
douchey
dumbass
dumshit
erotism
f u c k
f_u_c_k
f-u-c-k
f.u.c.k
fagging
faggitt
faggots
fagtard
fatfuck
fcuking
felcher
fellate
figging
fisting
footjob
fuckass
fuckbag
fuckboy
fuckers
fuckher
fucking
fuckme 
fucknut
fuckoff
fucktoy
fuckwad
fuckwit
fuckyou
fukkers
fukwhit
gay sex
gayfuck
gaylord
gaytard
girl on
god-dam
goddamn
handjob
hard on
hooters
humping
j3rk0ff
jackass
jackoff
jerk0ff
jerkass
jerkoff
jigaboo
kinbaku
knobead
knobend
kondums
kooches
kumming
lameass
lardass
lesbian
lezbian
lezzies
lusting
mafugly
munging
nawashi
neonazi
nig nog
nig-nog
nigaboo
niggers
nipples
nobhead
nutsack
old bag
orgasim
orgasms
panooch
panties
pegging
phallic
phuking
phukked
pissers
pissing
pissoff
pisspig
playboy
pollock
poonani
poonany
punanny
punkass
pussies
raghead
reetard
rimming
rubbish
s_h_i_t
s-h-1-t
s-h-i-t
sandbar
sandler
schlong
screwed
scrotum
shagger
shaggin
shemale
shibari
shitass
shitbag
shiting
shitted
shitter
shiznit
slutbag
sod off
splooge
strapon
suckass
sucking
swinger
t1tt1e5
t1tties
tied up
titfuck
tittie5
titties
titwank
topless
trumped
tubgirl
twinkie
twunter
upskirt
vjayjay
wankjob
wetback
whoring
willies
wiseass
x-rated
arsehole
ass fuck
ass hole
assbangs
assclown
assfaces
assfukka
assholes
assklown
asslover
assmucus
assmunch
assshole
asswhole
asswhore
asswipes
babeland
ball gag
ballsack
bangbros
bareback
barfface
bastardo
bastards
bazongas
beastial
beeyotch
big tits
birdlock
bisexual
bitchass
bitchers
bitching
blow job
blow mud
blowjobs
blumpkin
bollocks
booooobs
buggered
bullcrap
bulldike
bulldyke
bullshit
bunghole
buttbang
buttface
buttfuck
butthead
butthole
buttmuch
buttplug
c.o.c.k.
cameltoe
camwhore
choc ice
clitface
clitfuck
clitoris
clitorus
cockbite
cockface
cockhead
cocklump
cocknose
cockshit
cocksuck
cocksuka
coksucka
coonnass
cornhole
creampie
cumshots
cumstain
cuntface
cuntfuck
cunthole
cuntlick
cuntslut
cut rope
cyberfuc
cybersex
daterape
dick shy
dick-ish
dickface
dickfuck
dickhead
dickhole
dickmilk
dickslap
dickweed
dillweed
doochbag
dry hump
dumb ass
dumbcunt
dumbfuck
dumbshit
eatpussy
erection
essohbee
fastfuck
felching
fellatio
feltcher
fistfuck
footfuck
foreskin
freefuck
frotting
fuck off
fuck you
fuck-ass
fuckbutt
fuckedup
fuckface
fuckhead
fuckhole
fuckings
fuckmeat
fucknutt
fucktard
fucktart
fucktwat
fuckwhit
fuckwitt
futanari
gangbang
genitals
god damn
godamnit
godsdamn
golliwog
goo girl
goodpoop
goregasm
gotohell
ham flap
hand job
hardcore
headfuck
horniest
hot carl
hotpussy
huge fat
jack off
jack-off
jackhole
jailbait
jerk off
jerk-off
jiggaboo
kinkster
knob end
knobbing
knobhead
lesbians
lezbians
ma5terb8
masterb8
mcfagget
mr hands
nobjocky
nobjokey
numbnuts
nut sack
omorashi
orgasims
orgasmic
pedobear
phonesex
phukking
piss off
piss pig
piss-off
ponyplay
poontang
retarded
s.h.i.t.
santorum
scantily
screwing
shagging
shit ass
shitcunt
shitdick
shitface
shitfuck
shitfull
shithead
shithole
shitings
shitters
shittier
shitting
slanteye
slutkiss
smartass
sodomize
strap on
swastika
taste my
testical
testicle
tit wank
tub girl
twathead
twatlips
vajayjay
veqtable
vibrator
whorebag
zoophile
analannie
analprobe
anilingus
assbagger
assbandit
assbanged
assbanger
assfucker
assgoblin
asshopper
assjacker
assjockey
asskisser
asslicker
assmaster
assmonkey
assnigger
asspacker
asspirate
assranger
asssucker
ball sack
barenaked
bastinado
batty boy
bi-sexual
bicurious
big black
bitch tit
bitchtits
brassiere
breastjob
breastman
bull shit
bullshits
bullturds
bung hole
butchdike
butchdyke
butt fuck
butt plug
butt-bang
butt-fuck
buttfucka
buttmunch
camel toe
chesticle
cock snot
cockblock
cocklover
cockmunch
cocksmith
cocksmoke
cocksucer
cocksuck 
cocksucks
cocksukka
cum freak
cumbubble
cumjockey
cunt hair
cuntlick 
cuntsicle
cyberfuck
date rape
dick head
dick hole
dickbrain
dickheads
dickjuice
dog style
douchebag
dp action
dumbasses
ejaculate
ejakulate
fagfucker
fatfucker
fingering
fist fuck
fistfucks
freakfuck
fuck hole
fuck-tard
fuckbrain
fuckfreak
fuckheads
fuckstick
fucktards
fuckwhore
gang bang
gang-bang
gangbangs
gassy ass
goddammit
goddamned
goddamnit
group sex
hard core
holy shit
hot chick
jackasses
jail bait
jiggerboo
knobjocky
knobjokey
m-fucking
masochist
masterbat
mothafuck
muff puff
muffdiver
nob jokey
octopussy
pedophile
penetrate
phone sex
pigfucker
pissflaps
poopchute
queerbait
queerhole
rosy palm
shamedame
shitblimp
shiteater
shitfaced
shitheads
shithouse
shitstain
shittiest
shittings
shrimping
skullfuck
strappado
threesome
throating
tittyfuck
tittywank
towelhead
tribadism
urophilia
wet dream
wh0reface
whoreface
wiseasses
zoophilia
ass-fucker
ass-jabber
ass-pirate
assblaster
asscracker
assmuncher
asspuppies
autoerotic
baby juice
ball gravy
balllicker
bestiality
bigbastard
black cock
bloodclaat
booooooobs
booty call
bumblefuck
buttcheeks
buttfucker
c0cksucker
chota bags
circlejerk
cockburger
cockfucker
cockjockey
cockknoker
cocklicker
cockmaster
cockmonkey
cocknugget
cocksmoker
cocksucked
cocksucker
cockwaffle
cokmuncher
corksucker
corp whore
crackwhore
cumguzzler
cunilingus
cuntfucker
cunthunter
cuntlicker
cuntsucker
deepthroat
dickdipper
dickfucker
dickmonger
dickripper
dicksipper
dicksucker
dickweasel
dickzipper
dog-fucker
doggystyle
domination
dominatrix
doublelift
douche-fag
douchebags
eat a dick
eat my ass
ejaculated
ejaculates
facefucker
faggotcock
fannyflaps
fingerbang
fingerfuck
fistfucked
fistfucker
fleshflute
footfucker
footlicker
fuck-bitch
fuckbutter
fuckmehard
fuckmonkey
fucknugget
gangbanged
gayfuckist
giant cock
god-damned
gonorrehea
homoerotic
kunilingus
lovemaking
m45terbate
ma5terbate
masterbat3
masterbate
masturbate
menstruate
mothafucka
mothafucks
motherfuck
mthrfucker
muff diver
muffdiving
nut butter
p.u.s.s.y.
paedophile
peckerhead
pedophilia
perversion
pissed off
polesmoker
poop chute
poopuncher
prostitute
pussy fart
rumprammer
sandnigger
scissoring
shitbagger
shitbrains
shitbreath
shitcanned
slutdumper
smartasses
strip club
teabagging
twatwaffle
undressing
whorehouse
auto erotic
baby batter
beardedclam
beastiality
beaver lips
big breasts
bloody hell
blue waffle
breastlover
bullet vibe
bullshitted
bust a load
butt-fucker
butt-pirate
buttmuncher
chi-chi man
clit licker
clusterfuck
cock pocket
cock sucker
cock-sucker
cockholster
cockknocker
cockmongler
cockmuncher
cocksniffer
cocksuckers
cocksucking
coprolagnia
coprophilia
crack-whore
cum chugger
cum guzzler
cumdumpster
cunillingus
cunnilingus
cunt-struck
cuntlicker 
cuntlicking
cyberfucked
cyberfucker
deep throat
dick-sneeze
dickbeaters
dickflipper
dicksucking
dicktickler
dickwhipper
dingleberry
doggiestyle
doggy style
doggy-style
donkeypunch
double dong
ejaculating
ejaculation
enlargement
f u c k e r
fannybandit
fannyfucker
fartknocker
fingerfuck 
fingerfucks
fistfucker 
fistfuckers
fistfucking
foot fetish
fuck puppet
fuck trophy
fudgepacker
girl on top
hardcoresex
how to kill
intercourse
jelly donut
junglebunny
lemon party
master-bate
mothafuckas
mothafuckaz
mothafucked
mothafucker
mothafuckin
motherfucka
motherfucks
mtherfucker
mthrfucking
muthafecker
muthafuckaz
nimphomania
nsfw images
nymphomania
pedophiliac
penetration
penisbanger
penisfucker
penispuffer
pillowbiter
pole smoker
porchmonkey
pornography
prickteaser
sand nigger
shit fucker
shitspitter
slut bucket
snowballing
spread legs
style doggy
tea bagging
thundercunt
tight white
tittyfucker
tongue in a
transsexual
two fingers
unclefucker
venus mound
violet wand
white power
whorehopper
anal impaler
anal leakage
ball kicking
ball licking
ball sucking
barely legal
beatyourmeat
beef curtain
big knockers
bunny fucker
child-fucker
cockmongruel
cum dumpster
cyberfuckers
cyberfucking
dawgie-style
dendrophilia
doggie style
doggie-style
donkey punch
donkeyribber
douchewaffle
eat hair pie
ejaculatings
fingerfucked
fingerfucker
fistfuckings
flog the log
freakyfucker
fuck buttons
fuck yo mama
fuckersucker
fuckingbitch
fudge packer
fudge-packer
goldenshower
homodumbshit
iberian slap
jungle bunny
make me come
masterbating
masterbation
masturbating
masturbation
menstruation
mothafuckers
mothafucking
motherfucked
motherfucker
motherfuckin
motherfuckka
muthafuckker
mutherfucker
muthrfucking
porch monkey
pussy palace
pussylicking
pussypounder
raging boner
shaved pussy
shirt lifter
sultry women
sumofabiatch
tainted love
tittiefucker
urethra play
vorarephilia
whoralicious
wrapping men
`.split('\n').filter(item=> item.length);