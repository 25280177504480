import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import uiReducer from './ui';
import wordSearchReducer from './wordSearch';
import puzzleReducer from './puzzle';

const rootReducer = combineReducers({
  ui: uiReducer,
  wordSearch: wordSearchReducer,
  puzzle: puzzleReducer,
});

const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;